<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Edit deal information
			</div>
		</div>

		<div>
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="Name"
					type="text"
					rules="required|max:30"
					v-model="group.name"
				/>
			</validation-observer>
			<actions
				:actions="getActions"
				@actionClick="onActionClick"
			/>
		</div>
		<portal
			to="modal"
			v-if="deleteConfirmationIsVisible"
		>
			<modal-inner
				@close="toggleDeleteConfirmation"
				heading="Delete group"
			>
				<confirmation-modal
					text="Deleting the group will permanently remove it and all of the deals it contains."
					confirmationText="Delete group"
					confirmationActionId="deleteGroup"
					cancelActionId="toggleDeleteConfirmation"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>
	import InputField          from '@/components/forms/InputField';
	import Actions             from '@/components/ui/Actions';
	import ModalInner          from '@/components/ui/modal/ModalInner';
	import ConfirmationModal   from '@/components/ui/modal/ConfirmationModal';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import api                 from '@/services/api';

	export default {

		layout: 'v2default',

		components: {
			InputField,
			Actions,
			ModalInner,
			ConfirmationModal
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			group: {
				name: undefined
			},
			deleteConfirmationIsVisible: false
		}),
		computed: {
			getGroupId () {
				return this.$route.params?.groupId;
			},
			getCrumbRoute () {
				return '/teaching/deals';
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							actionId: 'saveAndReturnToDealsList'
						},
						{
							text: 'Cancel',
							route: this.getCrumbRoute
						}
					],
					secondary: [
						{
							text: 'Delete group',
							actionId: 'toggleDeleteConfirmation'
						}
					]
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/deals',
						text: 'Deal library'
					},
					{
						path: `/teaching/deals/groups/${this.getGroupId}`,
						text: this.group.name
					}
				];
			}
		},
		mounted () {
			this.setGroup();
		},
		methods: {
			toggleDeleteConfirmation () {
				this.deleteConfirmationIsVisible = !this.deleteConfirmationIsVisible;
			},
			async saveAndReturnToDealsList () {
				await this.updateGroup();
				await this.$router.push(this.getCrumbRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'The deal has been updated'
				});
			},
			async updateGroup () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const group = await api.deals.updateGroupById({
					groupId: this.getGroupId,
					group: this.group
				});
				if (!group) {
					return false;
				}
			},
			async setGroup () {
				const group = await api.deals.getGroupById({
					groupId: this.getGroupId
				});
				if (!group) {
					this.showInvalidFormMessage();
					return;
				}
				this.group = group;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async deleteGroup () {
				const response = await api.deals.deleteGroupById({
					groupId: this.getGroupId
				});
				if (!response) {
					return false;
				}
				await this.$router.push('/teaching/deals');
				this.$store.commit('ui/showNotification', {
					notification: 'The deal group has been deleted.'
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-element="main"] {
	}
</style>
